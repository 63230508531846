import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { connect } from 'react-redux'
import EellooTests from './EellooTests'
import TrajectoryNotes from './TrajectoryNotes'
import ApplicationsPanel from './ApplicationsPanel'
import ApplicationHandbook from './ApplicationHandbook'
import DocumentsPanel from '../Documents/DocumentsPanel'
import TrajectoryDocumentsPanel from './TrajectoryDocumentsPanel'

class ReintegrationPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
        }
    }

    render() {
        const { auth, user } = this.props

        return (
            <div>
                <Panel className="panel--multi">
                    <DocumentsPanel showProfileExport={false} />

                    <div className="spacer" />
                    <div className="spacer-hr" />

                    <ApplicationsPanel />

                    <div className="spacer" />
                    <div className="spacer-hr" />

                    <EellooTests profile={user.profile} showDetails={true} />

                    <div className="spacer" />
                    <div className="spacer-hr" />

                    <TrajectoryDocumentsPanel />

                    <div className="spacer" />
                    <div className="spacer-hr" />

                    <TrajectoryNotes profile={user.profile} showDetails={true} />

                    <div className="spacer" />
                    <div className="spacer-hr" />

                    <ApplicationHandbook />
                </Panel>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(ReintegrationPage)
