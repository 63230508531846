export default {
    datepicker: { confirm: 'Bevestig', title: 'Kies een datum', cancel: 'Annuleer' },
    login: {
        email_error: 'Vul een geldig e-mailadres in.',
        empty_field_error: 'Je hebt één of meerdere velden niet ingevuld.',
        auth_error: 'De ingevoerde gegevens zijn niet juist.',
        header: 'Inloggen',
        header_text: 'Log hier in met je e-portfolio account, of maak een nieuw account aan.',
        intro_text:
            '<h1>E-Portfolio</h1>\n<h3>Digitaal loopbaandossier</h3>\n<p>E-portfolio is onderdeel van VOTA en is ontwikkeld om mensen te helpen bij het solliciteren op vacatures in het onderwijs. E-portfolio is een soort digitaal loopbaandossier dat je steeds verder kunt uitbreiden, naarmate je bijvoorbeeld meer cursussen afrondt, meer werkervaring opdoet en meer interesses ontwikkelt.</p>\n\n<h3>Digitale kluis</h3>\n<p>Gebruik E-portfolio als je digitale kluis en bewaar eenvoudig en op een veilige manier relevante documenten, die je misschien later wilt delen met (potentiële) werkgevers of andere partijen.</p>\n\n<h3>Privacy</h3>\n<p>Privacy vinden we net zo belangrijk als jij, daarom heb je zelf totale controle over wat je toevoegt aan je E-portfolio en wat je deelt met andere partijen.</p>',
    },
    register: {
        gender: 'Geslacht',
        male: 'Man',
        female: 'Vrouw',
        password_criteria:
            'Het wachtwoord moet minimaal 6 tekens lang zijn en bestaan uit minimaal een hoofdletter, kleine letter, cijfer en een speciaal teken (?!@#$%%#%).\n',
        create_account: 'Account aanmaken',
        i_accept:
            'Door een account te maken ga je akkoord met onze privacy voorwaarden. Je vindt ze hier:',
        tou: 'Privacy Voorwaarden',
        create_an_account: 'Maak hier een nieuw account aan.',
        register_success:
            'We hebben je een activatie-e-mail toegestuurd. Klik op de link in de e-mail om jouw account te activeren.',
        activate_success: 'Je account is geactiveerd. Je kunt nu inloggen!',
        contain: 'Wachtwoorden moeten minimaal bestaan uit:',
        password_criteria_capital: 'een hoofdletter',
        password_criteria_small: 'een kleine letter',
        password_criteria_minimum: '8 tekens',
        password_criteria_number: 'een cijfer',
        password_criteria_special_character: 'een speciaal teken (bijv. *,?,!,@,#,$,%,#)',
        password_criteria_match: 'Let op dat je bij wachtwoord 2x precies hetzelfde invult. ',
        account_validity:
            'Als je al een VOTA account hebt, gebruik dan hetzelfde e-mailadres om in te loggen.',
    },
    buttons: {
        '2fa_verify': 'Bevestig Two-factor Authenticatie',
        activate_header: 'Account activeren',
        activate: 'Activeer mijn account',
        continue: 'Verder',
        login: 'Inloggen',
        create_account: 'Account aanmaken',
        forgot_password: 'Wachtwoord vergeten?',
        create_account_cta: 'Maak account aan',
        send_email: 'Verstuur e-mail',
        change_password: 'Verander wachtwoord',
        previous_page: 'Vorige pagina',
        back: 'Vorige',
        to_login: 'Naar login',
        more_info: 'Meer info',
        save: 'Opslaan',
        all_roles: 'Alle rollen',
        apply: 'Solliciteren',
        cancel: 'Annuleren',
        send: 'Versturen',
        remove: 'Verwijderen',
        image: 'Afbeelding',
        other: 'Overig',
        view: 'Bekijk',
        upload: 'Uploaden',
        click_here: 'Klik hier',
        close: 'Sluiten',
        next: 'Volgende',
        previous: 'Vorige',
        set_employer: 'Ik werk bij %{employer}!',
        view_vacancies: 'Bekijk vacatures',
        showSuggestions: 'OK, laat suggesties zien',
        getStarted: 'Starten!',
        goToVacancy: 'Naar de app',
        saveVacancy: 'Ja, sla vacature op',
        completeProfile: 'Voltooi profiel',
        edit: 'Wijzigen',
        '2fa': 'Two-factor authenticatie',
        clear: 'Duidelijk',
        add_another_evaluator: 'Voeg nog een beoordelaar toe',
        remove_evaluator: 'Beoordelaar verwijderen',
        add_evaluator: 'Feedback meerdere kwalificaties tegelijk',
        fill_in_profile: 'Vul mijn profiel aan',
        log_out: 'OK, uitloggen',
        redirect_deventer_badgecollect: '',
    },
    errors: {
        empty_field: 'Je hebt één of meerdere velden niet ingevuld.',
        password_too_short: 'Het wachtwoord moet ten minste 6 tekens bevatten.',
        password_no_uppercase: 'Het wachtwoord moet ten minste 1 hoofdletter bevatten.',
        password_no_lowercase: 'Het wachtwoord moet ten minste 1 kleine letter bevatten.',
        password_no_digit: 'Het wachtwoord moet ten minste 1 cijfer bevatten.',
        password_no_special: 'Het wachtwoord moet ten minste 1 speciaal teken (!@#$%^&*) bevatten.',
        password_no_match: 'De wachtwoorden komen niet overeen.',
        terms_not_accepted:
            'Je moet de Algemene Voorwaarden accepteren voordat je jouw account kunt aanmaken.',
        email_not_valid: 'Vul een geldig e-mailadres in.',
        bad_token_forgot:
            'Je token is niet meer geldig. Vraag een nieuw token aan om je wachtwoord te veranderen.',
        general: 'Er ging iets fout. Probeer het over enkele ogenblikken opnieuw.',
        format_not_allowed: 'Bestand heeft niet-toegestaan bestandstype.',
        size_too_large: 'Dit bestand is te groot. Een bestand mag maximaal 10MB zijn.',
        cannot_apply: 'Je kunt deze vacature niet aanvragen',
        no_gender: 'Je hebt nog geen geslacht geselecteerd',
        password_requirements_not_met: 'Niet alle eisen voor het nieuwe wachtwoord zijn voldaan.',
        hours_per_week_not_valid: 'De contracturen moeten hoger dan 1 en lager dan 60 zijn.',
        wrong_file_type: 'Bestand heeft geen toegestane extensie.',
        account_item_already_present: 'Dit item is al toegewezen aan uw profiel.',
        birthday_error_required: 'Selecteer je geboortedatum.',
        organisation_is_required: 'Vul a.u.b. de organisatie in',
        photo_5mb_size: 'Foto mag niet meer dan 5 MB zijn.\n',
        size_error: 'Bestand is te groot. Maximale grootte is %{size} kb',
        download_approved_document: 'Er is iets mis gegaan met het downloaden van uw bestand. ',
    },
    headers: {
        forgot_password: 'Wachtwoord vergeten',
        reset_password: 'Wachtwoord veranderen',
        oops: 'Oeps...',
        something_wrong: 'Er ging iets mis',
        activated: 'Account geactiveerd',
        activate_error: 'Er ging iets fout...',
        activating: 'Activeren',
        email_sent: 'E-mail verzonden',
        tou: 'Algemene Voorwaarden',
        roles: 'Rollen',
        competences: 'Competenties',
        vacancy_not_found: 'Vacature niet gevonden',
        vacancy_internal: 'Interne vacature',
        vacancy_not_available: 'Vacature niet beschikbaar',
        topics: 'Onderwerpen',
        school_subjects: 'Vakken',
    },
    footer: {
        about: 'Over e-portfolio',
        privacy_and_terms: 'Privacy & Voorwaarden',
        contact: 'Contact',
        help: 'Help',
        roadmap: 'Roadmap',
    },
    menu: {
        profile: 'Mijn Profiel',
        suggestions: 'Suggesties',
        logout: 'Uitloggen',
        vacancies: 'Vacatures',
        resumes: 'CV-sjablonen',
        templates: 'CV-sjablonen',
        applications: 'Sollicitaties',
        logging_out: 'Bezig met afmelden',
        intro: 'Handleiding',
        security: 'Veiligheid',
        sharing: 'Delen',
        privacy: 'Privacy',
    },
    modal: { close_window: 'Sluit venster' },
    texts: {
        forgot_password:
            'Vul hieronder het e-mailadres van je account in. We sturen daar een mail naartoe waarmee je je wachtwoord kunt herstellen.',
        reset_password: 'Vul hieronder het nieuwe wachtwoord in en herhaal deze daaronder.',
        not_found:
            'De pagina die je probeerde te openen bestaat niet. <br />Klik op de knop hieronder om terug te gaan naar de vorige pagina.',
        activated:
            'Je account is geactiveerd. Je kunt nu inloggen door op de knop hieronder te klikken.',
        email_sent:
            'We hebben een e-mail met een activatielink verstuurd naar\n<br /><strong>%{email}</strong><br />\nMocht je toch geen email ontvangen, kijk dan even in je spam folder voor je contact met ons opneemt.',
        check_spam_folder:
            'Zie je de e-mail niet in je inbox? Vergeet dan niet te kijken in je spamfolder.',
        activate_your_account:
            'Activeer je account door op de link in de e-mail te klikken. Nadat je account geactiveerd is kun je inloggen.',
        forgot_email_sent:
            'Als er een account bekend is met het opgegeven e-mailadres, dan is er een e-mail verzonden met een link om je wachtwoord te veranderen.',
        password_reset_success:
            'Je wachtwoord is veranderd. Je kunt nu inloggen met je nieuwe wachtwoord.',
        activating: 'Je account wordt geactiveerd',
        competences: 'Selecteer hieronder de competenties die bij jou passen.',
        wrong_employer: 'Deze vacature is alleen beschikbaar voor werknemers van %{employer}.',
        wrong_employer_work_at: 'Werk je bij %{employer}? Klik dan op de onderstaande knop.',
        vacancy_not_found: 'De vacature die je probeerde te openen kan niet gevonden worden.',
        hint_loading: 'Laden...',
        flexapp_activated:
            'Je kunt nu inloggen om jouw e-portfolio profiel verder aan te vullen.\nNadat jouw werkgever de aanmelding heeft goedgekeurd, kun je de Flex-app gebruiken. Je ontvangt hiervan een mail.',
        evaluator_activated:
            'Je account is geactiveerd. Je kunt nu inloggen op Kwalificatiedossier om de evaluatie uit te voeren.',
    },
    labels: {
        smsToken: 'SMS token',
        firstname: 'Voornaam',
        initials: 'Voorletters',
        lastname: 'Achternaam',
        man: 'Man',
        woman: 'Vrouw',
        email: 'E-mailadres',
        password: 'Wachtwoord',
        repeat_password: 'Herhaal wachtwoord',
        password_again: 'Herhaal wachtwoord',
        hours: 'uur',
        vacancy: 'Vacature',
        street: 'Straat',
        housenumber: 'Huisnummer',
        suffix: 'Toevoeging',
        zipcode: 'Postcode',
        city: 'Plaats',
        phonenumber: 'Telefoonnummer',
        mobilenumber: 'Mobiel nummer',
        birthday: 'Geboortedatum',
        birthplace: 'Geboorteplaats',
        remember_me: 'Onthoud mij',
        or: 'of',
        invite_type: 'Rol',
        work_processes: 'Werkprocessen',
        date: 'Datum',
        company: 'Bedrijf',
        result: 'Resultaat',
        attachment: 'Bijlage',
        in_progress: 'Lopend',
        rejected: 'Afgewezen',
        applied: 'Gesolliciteerd',
        interview: 'In gesprek',
        expiration_date: 'Aanvraag intrekken op',
        work_process: 'Werkproces',
        first_name: 'Voornaam',
        last_name: 'Achternaam',
        gender: 'Geslacht',
        address: 'Adres',
        address_number: 'Huisnummer',
        address_number_suffix: 'Toevoeging',
        postal_code: 'Postcode',
        city_name: 'Woonplaats',
        phone_number: 'Telefoonnummer',
        mobile_number: 'Mobiel nummer (voor gratis SMS alerts)',
        date_of_birth: 'Geboortedatum',
        place_of_birth: 'Geboorteplaats',
        iban: 'IBAN',
        discovery_method: 'Hoe ben je te weten gekomen over VOTA?',
    },
    cards: {
        apply_job_offer: 'Solliciteren',
        no_suggestions: 'We kunnen nog geen<br /> suggesties vinden',
        complete_profile:
            'Het uitbreiden van je profiel helpt ons om suggesties voor je te vinden.',
        button_complete_profile: 'Profiel aanvullen',
        loading: 'Laden',
        fetching_suggestions: 'We zijn suggesties voor je aan het ophalen',
        new_suggestions_explanation:
            'Als we nieuwe suggesties voor jou gevonden hebben dan worden die hier automatisch weergegeven.',
        back_to_profile: 'Terug naar profiel',
        all_suggestions_answered: 'Goed gedaan, je hebt alle suggesties beantwoord!',
        hi: 'Hoi',
        suggestions_found: 'we hebben deze suggesties voor je gevonden',
        back: 'Terug',
        feedback_message_job:
            'Bedankt voor je interesse in deze vacature. We sturen je een e-mail met meer informatie.',
        city: 'Plaats',
        job_working_time: 'Soort baan',
        privacy_settings:
            'We hebben geen toegang tot de informatie die we nodig hebben om suggesties voor je te vinden. De gegevens die we gebruiken zijn:\n<br>\n<br>Steden\n<br>Competenties\n<br>Functies\n<br>Opleidingsniveau\n<br>Werkgebied\n<br>Werktijden\n<br>\n<br>En, als je ook interesse hebt in interne vacatures:\n<br>\n<br>Organisaties',
        button_privacy_settings: 'Privacy instellingen',
        missing_fields_profile:
            'We kunnen misschien meer vinden als je de volgende velden invult op jouw profiel: ',
        improve_profile:
            'Je krijgt misschien meer resultaten als je jouw mogelijke beroepen of werkgebieden uitbreidt',
        missing_field_profile:
            'We kunnen misschien meer vinden als je het volgende veld invult op jouw profiel: ',
    },
    profile: {
        verify: 'Bevestig',
        '2fa_message': 'Vul de code in die je per SMS hebt ontvangen om verder te gaan',
        '2fa_enabled': 'Two-factor authenticatie is nu geactiveerd op je account.',
        current_password: 'Huidig wachtwoord',
        new_password: 'Nieuw wachtwoord',
        repeat_password: 'Herhaal wachtwoord',
        password_changed: 'Je wachtwoord is aangepast',
        pick_file_type: 'Kies het bestandstype',
        upload_success: 'Het bestand is succesvol geüpload.',
        no_extra_info: 'Je hebt nog geen extra informatie ingevuld.',
        no_organization: 'Geen',
        add_extra_info: 'Toevoegen',
        remove_2fa: 'Verwijder two-factor authenticatie',
        setup_2fa: 'Stel two-factor authenticatie in',
        select: 'Selecteer',
        file_name: 'Bestandsnaam',
        upload: 'Uploaden',
        attachments: 'Bestanden',
        fetching_file: 'Bestand ophalen...',
        twoFactorToken: 'Token',
        loading_files: 'Laden van je bestanden...',
        drag_drop_to_upload: 'Sleep hier bestanden naartoe om te uploaden',
        export: 'Exporteren',
        update_resume:
            'Let op: je hebt je profiel aangevuld, deze nieuwe gegevens worden pas zichtbaar in je sjablonen als je deze aanvinkt via:',
        download: 'Downloaden',
        documents: 'Documenten',
        export_message:
            'Gebruik de onderstaande knoppen om je CV te downloaden als bestand (als NEN 2035:2014 formaat).',
        export_message_2:
            'Gebruik de onderstaande knoppen om je CV te downloaden als bestand (als NEN 2035:2014 formaat).',
        export_message_1:
            'Gebruik de onderstaande knop om je CV naar jezelf te e-mailen (als NEN 2035:2014 formaat).',
        download_zip: 'Downloaden ZIP',
        zip_downloaded: 'Het ZIP-bestand van je profiel is opgeslagen',
        zip_emailed: 'Er is een e-mail verstuurd met je geëxporteerde profiel',
        email_attachment: 'Bestand e-mailen naar jezelf',
        emailing: 'Versturen...',
        emailed: 'Verstuurd!',
        manage_job_interests: 'Interessante functies',
        no_job_interests: 'Je hebt nog geen functies toegevoegd die je interessant zou vinden.',
        manage_roles: 'Rollen',
        manage_comptences: 'Competenties',
        competences: 'Competenties',
        employer_internal_vacancies: 'Werkgever',
        current: 'Huidige Baan',
        name: 'Naam',
        gender: 'Geslacht',
        email: 'E-mail',
        address: 'Adres',
        phone: 'Telefoon',
        birth_date: 'Geboortedatum',
        january: 'Januari',
        february: 'Februari',
        march: 'Maart',
        april: 'April',
        may: 'Mei',
        june: 'Juni',
        july: 'Juli',
        august: 'Augustus',
        september: 'September',
        october: 'Oktober',
        november: 'November',
        december: 'December',
        edit: 'Bewerken',
        stop_edit: 'Opslaan',
        remove: 'Verwijderen',
        cancel: 'Annuleren',
        save: 'Opslaan',
        else: 'Overig',
        personal_data: 'Persoonlijke gegevens',
        remove_photo: 'Verwijder foto',
        upload_different_photo: 'Upload andere foto',
        address_unknown: 'Geen adres bekend',
        phone_number_unknown: 'Geen telefoonnummer bekend',
        birthday_unknown: 'Geen geboortedatum bekend',
        qoute: 'Quote',
        quote_unavailable: 'Geen quote beschikbaar',
        quote_placeholder: 'Typ hier je quote...',
        current_function_unknown: 'Geen huidige functie',
        function: 'Functie',
        add_new: 'Voeg nieuwe toe',
        present_day: 'Heden',
        graduated: 'Diploma behaald',
        graduated_yes: 'Ja',
        graduated_no: 'Nee',
        experience: 'Werkervaring',
        no_experience:
            'Je hebt nog geen werkervaring ingevuld. Vul dit in zodat wij beter vacatures voor jou kunnen zoeken.',
        change_experience: 'Werkervaring aanpassen',
        add_experience: 'Voeg werkervaring toe',
        experience_function: 'Functie',
        experience_location: 'Bedrijf',
        experience_city: 'Plaats',
        experience_area: 'Werkgebied',
        experience_from: 'Vanaf',
        experience_until: 'Tot',
        education: 'Afgeronde opleidingen',
        no_education:
            'Je hebt nog niet ingevuld welke opleidingen je hebt gevolgd. Vul dit in zodat wij beter vacatures voor jou kunnen zoeken.',
        change_education: 'Opleiding aanpassen',
        add_education: 'Voeg opleiding toe',
        study: 'Studie',
        panel_education: 'Opleidingen en cursussen',
        education_different: 'Studie (anders)',
        education_location: 'Onderwijsinstelling',
        education_from: 'Vanaf',
        education_until: 'Tot',
        label_study_level: 'Opleidingsniveau',
        courses: 'Cursussen',
        course_name: 'Cursusnaam',
        extra_info: 'Extra Informatie',
        graduated_at: 'Behaald op',
        expires_on: 'Verloopt op',
        no_courses:
            'Je hebt nog niet ingevuld welke cursussen je hebt gevolgd. Vul dit in zodat wij beter vacatures voor jou kunnen zoeken.',
        no_uploading: 'Er zijn momenteel geen bestandstypes die je kunt uploaden.',
        change_courses: 'Cursus aanpassen',
        add_courses: 'Voeg cursus toe',
        references: 'Referenties',
        no_references:
            'Je hebt nog geen referenties ingevuld. Vul dit in zodat wij beter vacatures voor jou kunnen zoeken.',
        change_references: 'Referentie aanpassen',
        add_references: 'Voeg referentie toe',
        reference_company: 'Referentiebedrijf',
        reference_name: 'Naam',
        reference_phonenumber: 'Telefoonnummer',
        reference_description: 'Omschrijving',
        skills: 'Waar je goed in bent',
        no_skills:
            'Je hebt nog niet ingevuld waar je goed in bent. Vul dit in zodat wij beter vacatures voor jou kunnen zoeken.',
        panel_skills: 'Vaardigheden',
        add_skills: 'Vul hier in waar je goed in bent.',
        job_interest: 'Jouw mogelijke beroepen',
        no_job_interest:
            'Je hebt nog geen mogelijk beroepen ingevuld. Vul dit in zodat wij beter vacatures voor jou kunnen zoeken.',
        language: 'Taal',
        languages: 'Talen',
        no_languages:
            'Je hebt nog niet ingevuld welke talen je beheerst. Vul dit in zodat wij beter vacatures voor jou kunnen zoeken.',
        change_languages: 'Taal aanpassen',
        add_languages: 'Voeg taal toe',
        speaking: 'Spreken',
        reading: 'Lezen',
        writing: 'Schrijven',
        interests: 'Interesses',
        no_interests:
            'Je hebt nog niet ingevuld waar je geïnteresseerd in bent. Vul dit in zodat wij beter vacatures voor jou kunnen zoeken.',
        add_interests: 'Vul hier in waar je geïnteresseerd in bent.',
        datepicker: 'Klik hier om een datum te kiezen...',
        no_value: 'Geen waarde',
        title_cards_redirect: 'Competentievragen',
        confirm_cards_redirect:
            'Op basis van de rollen die je hebt toegevoegd kunnen we je suggesties geven voor competenties. Wil je deze suggesties zien of verder gaan met je profiel aanpassen?',
        button_cards_redirect: 'Naar suggesties',
        button_continue_editing: 'Terug naar profiel',
        jobs_modal_intro: 'Hieronder staan alle mogelijke beroepen',
        filter_jobs: 'Filter beroepen',
        no_results: 'Geen resultaten',
        filter_competences: 'Filter competenties...',
        competences_intro: 'Hieronder staan voorgestelde competenties op basis van je profiel.',
        competences_helper_part_1: 'Vind je het lastig om zelf competenties in te vullen? Klik dan',
        competences_helper_part_2: 'om je rollen te beheren.',
        competences_helper_part_3:
            'Op basis van deze rollen kunnen we je competenties voorstellen. Of klik',
        competences_helper_part_4: 'om alle competenties te zien.',
        compentences_helper_here: 'hier',
        upload_files: 'Bestanden uploaden',
        edit_privacy_explanation:
            'Je privacy instellingen zijn gewijzigd. Dit kan invloed hebben op de suggesties die je ziet in e-portfolio.',
        edit_profile_note: 'Let op',
        edit_profile_explanation:
            'Je hebt je CV gewijzigd. Deze nieuwe gegevens worden pas zichtbaar in je opgeslagen sjablonen als je deze wijzigt via Sjablonen',
        my_resumes: "Mijn CV's",
        account_type: 'Wat ben jij?',
        delete_profile: 'Profiel verwijderen',
        delete_info_list:
            'Wanneer je je profiel verwijdert, zullen onderstaande stappen direct worden uitgevoerd:',
        confirm_delete: 'Om je profiel te verwijderen, typ de volgende tekst:',
        confirm_delete_placeholder: 'Typ hier de tekst...',
        delete_item_export: 'Je kunt je e-portfolio niet meer exporteren',
        delete_item_personal: 'Je persoonlijke gegevens worden allemaal verwijderd',
        delete_item_files: 'Bestanden die je geupload hebt worden verwijderd',
        delete_item_reactions: '(Openstaande) vacaturereacties worden verwijderd',
        delete_item_signin: 'Je kunt niet meer inloggen via e-portfolio en VOTA',
        delete_item_profile_picture: 'Je profielfoto wordt verwijderd',
        delete_confirm: 'Vul de volgende code in ter bevestiging:',
        confirm_delete_item: 'Weet je zeker dat je %{item} wilt verwijderen?',
        default_account_resume: 'Werkgevers mogen mijn gegevens inzien',
        view_full_profile: 'Bekijk volledig profiel',
        see_more: 'Bekijk meer',
        see_less: 'Bekijk minder',
        downloading_file: 'Je bestand wordt gedownload...',
        deleting_file: 'Bestand verwijderen...',
        privacy: 'Privacyinformatie',
        privacy_shared: 'Gedeeld',
        privacy_private: 'Privé',
        external_sources: 'Externe partijen',
        zorgselect_website: 'ZorgSelect Website',
        interactions: 'Interactions',
        interactions_desc: 'Wanneer minstens één van bovenstaande items is aangevinkt',
        privacy_info:
            'Selecteer de persoonsgegevens die je met ons wilt delen zodat wij betere suggesties kunnen genereren.',
        resumes: 'CV-sjablonen',
        resumes_text: 'Opgeslagen CV-sjablonen voor gebruik in jouw toepassingen',
        vacancy_suggestions_email: 'Stuur mij een e-mail bij interessante vacatures',
        wants_project_vacancies: 'Ik sta open voor tijdelijke klussen',
        country_code: 'Landcode',
        phone_number: 'Telefoonnummer',
        no_shared_by_me:
            'Je hebt jouw profiel nog met niemand gedeeld. Klik op de knop hieronder om te beginnen met delen.',
        no_shared_with_me:
            'Nog niemand heeft zijn / haar profiel met jou gedeeld. Wanneer iemand een profiel met je deelt wordt deze hier getoond. ',
        share_profile: 'Deel je profiel',
        shared_by_me: 'Gedeeld door mij',
        shared_with_me: 'Gedeeld met mij',
        profile_picture: 'Profielfoto',
        panel_competences: 'Vaardigheden',
        diplomas: 'Huidige opleiding',
        no_diplomas:
            'Je hebt nog geen huidige opleidingen toegevoegd aan je profiel. Voeg ze toe om vacaturesuggesties te ontvangen.',
        add_diplomas: 'Voeg schoolopleiding toe',
        control_feedback_status:
            'Controleer hier wat de status is van jouw feedbackaanvragen. Deze informatie kun je alleen zelf zien.',
        evaluation: 'Beoordeling',
        confirm_delete_diploma: 'Weet je zeker dat je deze schoolopleiding wilt verwijderen?',
        sharing: 'Delen',
        no_competences:
            'Je hebt nog geen competenties toegevoegd aan je profiel. Voeg ze toe om vacaturesuggesties te ontvangen.',
        add_sollicitatie: 'Nieuwe sollicitatie',
        remarks: 'Opmerkingen',
        no_sollicitaties: 'Geen sollicitaties',
        add_new_diploma: 'Voeg een opleiding toe',
        achievements: 'Prestaties',
        profile_settings: 'Profielinstellingen',
        allowed_file_types: 'Toegestane bestandsformaten:',
        stop_sharing: 'Niet delen',
        flexapp: {
            register: {
                title: 'Flex-app registratie',
                text: 'Text uitleg over Flex-app',
                submit: 'Start registratieprocedure',
                registered: 'Je hebt al een verzoek tot inschrijven voor de Flex-app ingediend.',
            },
            modal: {
                title: 'Flex-app gegevens',
                text: 'Om jou te kunnen matchen aan beschikbare opdrachten, zijn er wat basisgegevens nodig. De volgende gegevens worden gebruikt:',
                working_areas_used: 'Werkervaringen',
                studies_used: 'Opleidingen',
                job_functions_used:
                    'Geef de functies aan waarvoor je in aanmerking zou willen komen',
                filter_job_functions: 'Filter functies',
                register: 'Verstuur registratieverzoek',
            },
        },
        not_shareable: 'Niet deelbaar',
        goto_test: 'Ga naar test',
        emrex: {
            studies_added:
                "Je geselecteerde diploma's zijn aan de opleidingen toegevoegd. \nEen aantal gegevens zul je zelf moeten aanpassen.",
            success: 'Opleidingen toegevoegd!',
            failed: 'Oeps!',
            something_went_wrong: "Er is iets mis gegaan bij het ophalen van de diploma's.",
            import_from_duo: "Haal diploma's op van DUO",
            download_diploma: 'Download diploma',
            cancelled: 'Importeren geannuleerd',
            studies_cancelled: "Het importeren van je diploma's vanuit DUO is geannuleerd.",
            request_made: "Je bent uitgenodigd om je diploma's te delen vanuit DUO.",
            verified: 'DUO geverifieerd diploma',
        },
        cannot_delete_profile:
            'Vanwege de bewaarplicht van trajecten, kan dit profiel momenteel niet verwijderd worden.',
        cannot_delete_profile_title: 'Kan profiel niet verwijderen',
        no_flexible_shift_employers:
            'Je hebt nog geen werkgevers gekozen waar je opdrachten zou willen doen. Vul dit in zodat wij je flexibele opdrachten kunnen aanbieden.',
        filter_flexapp_employers: 'Filter werkgevers',
        flexible_shift_employers: 'Organisatie(s) waar ik opdrachten wil doen',
        events: {
            upcoming_events: {
                title: 'Aankomende evenementen',
                support_text: 'Evenementen dicht bij de huidige datum',
            },
            sign_out: 'Afmelden',
            learn_more: 'Lees Meer',
            day_of_the_week: {
                Sunday: 'Zondag',
                Monday: 'Maandag',
                Tuesday: 'Dinsdag',
                Wednesday: 'Woensdag',
                Thursday: 'Donderdag',
                Friday: 'Vrijdag',
                Saturday: 'Zaterdag',
            },
            all_registered_events: {
                title: 'Al jouw geregistreerde evenementen',
                support_text: 'Alle evenementen waar je voor bent geregistreerd',
            },
            browse_and_register_for_events: {
                title: 'Verkennen van en registeren voor evenementen',
                support_text: 'Verken evenementen waar je voor kan registreren',
            },
            sign_up: 'Inschrijven',
            fields_of_interest: {
                title: 'Interessegebieden voor evenementen',
                support_text: 'Jouw huidige interessegebieden voor evenementen',
                none_selected:
                    'Je hebt nog geen interessegebieden voor evenementen ingevuld. Vul dit in zodat wij beter evenementen voor jou kunnen zoeken.',
            },
            already_registered: 'Je hebt je al aangemeld voor dit evenement',
            registration_successful: 'Succesvol toegevoegd aan jouw geregistreerde evenementen',
            confirm: 'Bevestig',
            are_you_sure:
                'Weet je zeker dat je jouw registratie voor dit evenement wilt annuleren:',
            from_date: 'Vanaf',
            to_date: 'tot',
        },
        flexapp_function_interest: 'Inzetbare functies',
        no_flexapp_functions: 'Geen functies gekozen',
        flexapp_functions_intro: 'Kies hieronder voor welke functies je inzetbaar bent',
        iban: 'IBAN',
        flexapp_settings: 'Flex-app-instellingen',
        flexapp_work_as_freelancer: "Ik wil (ook) ingezet worden als ZZP'er.",
        iban_description:
            'Dit hebben we nodig om opdrachten die je hebt gemaakt uit te kunnen betalen. Je kan later je rekeningnummer aanpassen via Mijn Profiel.',
        organisation_warning:
            'Bij het wijzigen van jouw werkgever kan je in de FlexApp niet meer deelnemen aan de tijdelijke opdrachten van jouw werkgever',
        flexapp_organisation_pending: 'in afwachting',
        account_resume_info:
            'Wil je dat je gegevens zichtbaar zijn voor werkgevers, maak dan eerst een CV sjabloon aan onder ‘Documenten\n& CV’',
        field_of_interest: {
            title: 'Jouw mogelijke interessegebieden',
            filter: 'Filter interessegebieden',
            intro: 'Hieronder staan alle mogelijke interessegebieden',
        },
        topics_intro: 'Selecteer relevante onderwerpen waar je bij betrokken bent',
        filter_topics: 'Filter onderwerpen...',
        topics: 'Onderwerpen',
        no_topics:
            'Je hebt nog niet ingevuld bij welke onderwerpen je betrokken bent. Vul dit in zodat relevante informatie voor jou wordt weergegeven.',
        eelloo_tests: 'Eelloo testen',
        trajectory_notes: {
            title: 'Notities',
            no_notes: 'Geen notities',
            author: 'Auteur',
            contents: 'Inhoud',
            see_more: '(bekijk meer)',
            see_less: '(bekijk minder)',
            comment_info: 'opmerking(en)',
            comments: {
                placeholder: 'Typ je opmerking hier...',
                title: 'Opmerkingen',
                send: 'Sturen',
            },
        },
        flexapp_organisation_rejected: 'afgekeurd',
        delete_info: 'Je profiel wordt verwijderd uit E-portfolio en VOTA',
        personal_information: 'Persoonlijke informatie',
        school_subjects: {
            title: 'Vakken',
            intro: 'Selecteer een vak en het niveau waarop u het lesgeeft.',
            subjects: { placeholder: 'Selecteer een vak...' },
            levels: { placeholder: 'Selecteer bevoegdheid...' },
            no_school_subjects:
                'Je hebt nog geen schoolvakken met bevoegdheid toegevoegd aan je profiel. Voeg ze toe om betere vacaturesuggesties te ontvangen.',
        },
        no_school_subjects:
            'Je hebt nog geen vakken toegevoegd aan je profiel. Voeg ze toe om vacaturesuggesties te ontvangen.',
        hours_till: 'Maximaal',
        hours_from: 'Minimaal',
        hours_per_week: 'Uren die je per week wilt werken',
        open_badges: { title: 'OpenBadges' },
        studies_placeholder: 'Kies een studie',
        study_levels_placeholder: 'Kies een opleidingsniveau',
        uploaded_files: 'bestanden geupload',
        applications: 'Sollicitaties',
        reintegration: 'Reïntegratie traject',
        client_applications: {
            title: 'Sollicitaties',
            results: {
                interview: 'Lopend',
                applied: 'Gesolliciteerd',
                rejected: 'Afgewezen',
                in_progress: 'In gesprek',
            },
            date: 'Datum',
            vacancy: 'Vacature / Sollicitatieactiviteit',
            company: 'Bedrijf',
            result: 'Resultaat',
            remarks: 'Opmerkingen',
            edit: 'Sollicitatie bewerken',
            add: 'Sollicitatie toevoegen',
        },
        add_application: 'Sollicitatie toevoegen',
        edit_application: 'Sollicitatie bewerken',
        no_application: 'Geen sollicitaties',
        edit_profile: 'Profiel bewerken',
        no_roles: 'Geen rollen',
        roles: 'Rollen',
        competences_helper_part_4_all: 'om alleen de voorgestelde te bekijken',
        approved_documents: 'Goedgekeurde documenten',
        no_approved_documents: 'Geen goedgekeurde documenten',
        document_approved: 'Document goedgekeurd',
        document_pending: 'Wachten op goedkeuring',
        document_rejected: 'Document afgewezen',
        document_informative: 'Geen actie nodig',
        trajectory_documents: 'Traject documenten',
        application_handbook: { title: '', body: '', button: '' },
    },
    vacancies: {
        apply: 'Solliciteer',
        view: 'Bekijk',
        upload_resume: 'Upload jouw CV (optioneel)',
        state_sent: 'Verstuurd',
        select_resume: 'Selecteer een CV',
        state_concept: 'Concept',
        state_expired: 'Verlopen',
        button_view_vacancy: 'Bekijken',
        button_finish_vacancy: 'Afronden',
        concepts: 'Concepten',
        sent: 'Verzonden',
        expired: 'Verlopen',
        button_send_application: 'Solliciteren',
        button_delete_concept: 'Verwijderen',
        button_save_concept: 'Opslaan',
        todo_upload_cv: 'Upload je CV',
        todo_write_motivation: 'Schrijf je motivatie',
        todo_send_application: 'Je hoeft je sollicitatie alleen nog te versturen',
        message_todo_intro:
            'Om deze sollicitatie te versturen moet je nog de volgende stappen ondernemen:',
        confirm_remove: 'Weet je zeker dat je deze vacature wilt verwijderen?',
        confirm_send: 'Weet je zeker dat je deze sollicitatie wilt versturen?',
        toast_title_sent: 'Verzonden',
        toast_msg_sent: 'Je sollicitatie voor %{name} is verstuurd.',
        internal: 'Interne vacature',
        fit_for_you: 'Wanneer past deze baan bij jou?',
        required_study: 'Vereist studieniveau',
        availability: 'Je bent beschikbaar vanaf',
        working_time: 'Je werkt',
        salary: 'Inschaling',
        remarks: 'Bijzonderheden',
        contact: 'Contactpersoon',
        location: 'Locatie',
        motivation: 'Motivatie',
        your_motivation: 'Jouw motivatie',
        placeholder_enter_motivation: 'Vul hier je motivatie in...',
        choose_existing_file: 'Kies uit je bestaande bestanden',
        select_a_file: 'Selecteer een bestand',
        select_profile_picture: 'Selecteer een profielfoto',
        no_files: 'Geen bestanden gevonden',
        upload_new_file: 'Of upload een nieuw bestand',
        upload_new_file_website: 'Of upload een nieuw bestand via de website.',
        sent_cv: 'Verstuurd CV',
        upload_your_cv: 'Upload jouw CV',
        vacancy: 'Vacature',
        working_area: 'Werkgebied',
        title_success: 'Verstuurd!',
        title_saved: 'Aanpassingen opgeslagen',
        message_saved: 'Je aanpassingen zijn succesvol opgeslagen.',
        message_success: 'Je sollicitatie is succesvol verstuurd.',
        resume_intro: 'Controleer hieronder je CV en maak indien nodig aanpassingen.',
        expand_job_description: 'Lees de functieomschrijving',
        hide_job_description: 'Verberg functieomschrijving',
        select_shared_fields:
            'Selecteer hieronder de velden die je wilt delen met de werkgever van deze vacature.',
        choose_template: 'Kies een opgeslagen sjabloon:',
        upload: 'Kies bestand',
        file_uploaded_and_attached:
            'Je geselecteerde CV is toegevoegd en wordt automatisch meegestuurd met je sollicitatie.',
        edit_cv: 'Bewerk CV',
        no_city_entered:
            'Je hebt geen stad ingevuld. Vul hiernaast een stad in om te zoeken naar vacatures in de buurt.',
        no_vacancies: 'We konden geen vacatures vinden die aan je zoekopdracht voldoen.',
        hint_search_city: 'Zoek op plaatsnaam...',
        hint_search_prompt: 'Typ de eerste drie letters van de plaatsnaam',
        apply_step: 'Stap',
        saveTemplate: 'CV-sjabloon opslaan',
        saveText:
            'Als je een gemaakt sjabloon opslaat, zorg je ervoor dat je deze snel opnieuw kunt gebruiken in toekomstige toepassingen. Je kunt de opgeslagen sjablonen vinden op je profielpagina.',
        checkboxSave: 'Sjabloon opslaan',
        chooseTemplate: 'Kies een sjabloon:',
        noCitiesFound: 'Geen steden gevonden',
        noOptions: 'Geen opties',
        'no_city_entered_small\n':
            'Je hebt geen stad ingevuld. Vul hieronder een stad in om te zoeken naar vacatures in de buurt.',
        apply_explanation:
            "Na het versturen van je sollicitatie ontvang je een ontvangstbevestiging in je mailbox. Is dit niet het geval, check dan je spam. Indien je geen bevestiging ontvangt stuur een e-mail naar <a href='mailto:info@vota.nl'>info@vota.nl</a>.<br/><br/><strong>Door verder te gaan ga je akkoord met onze <a target='_blank' href='https://vota.nl/privacyreglement-persoonsgegevens'>privacy policy</a>.</strong>",
        industry: 'Branche',
        hours_per_week: 'uur per week',
        sent_cv_file: 'Verstuurd bestand',
        applied_through: 'Gesolliciteerd via E-portfolio',
        unknown: 'Onbekend aantal uur',
    },
    resumes: {
        save_intro:
            'Vul hier de naam en omschrijving van je CV in zodat je deze later gemakkelijk kunt herkennen.',
        intro: 'Maak een sjabloon bestaande uit geselecteerde onderdelen van je profiel. Je kunt dit sjabloon later gebruiken bij het solliciteren op vacatures in e-portfolio.',
        add_resume: 'Nieuw sjabloon maken',
        edit: 'Bewerken',
        saved_resumes: 'Opgeslagen sjablonen',
        no_resumes:
            'Maak hier cv sjablonen aan die je kunt gebruiken om te delen en te solliciteren.',
        new_resume: 'Nieuw sjabloon',
        edit_resume: 'Wijzig sjabloon',
        remove_resume: 'Verwijderen',
        view_resume: 'Bekijken',
        create_intro: 'Selecteer hieronder welke onderdelen je toe wil voegen aan je sjabloon.',
        previous: 'Terug',
        save_resume: 'sjabloon opslaan',
        name: 'Naam',
        description: 'Korte beschrijving',
        sentResume: 'Ingezonden CV',
    },
    roadmap: {
        more_coming: 'Er komt meer aan',
        info: 'Binnenkort zullen er nieuwe mogelijkheden worden toegevoegd aan e-portfolio. Hieronder zie je een overzicht van deze nieuwe functies.',
        updates: 'Wil je op de hoogte blijven van onze updates?',
        email: 'Vul dan hieronder je E-mailadres in:',
        expected_date: 'De verwachte datum van afronding is:',
    },
    privacy: {
        account_id: {
            title: 'Account',
            description:
                'Op basis van jouw accountgegevens worden banen getoond die beter bij jou passen.',
        },
        cities: {
            title: 'Steden',
            description:
                'Op basis van de locaties van banen waarin je geïnteresseerd bent, worden betere suggesties in soortgelijke steden getoond.',
        },
        competences: {
            title: 'Competenties',
            description: 'Op basis van jouw competenties worden bijpassende banen getoond.',
        },
        external_hrorganizer: {
            title: 'HRorganizer.com',
            description:
                'Dit is een test met competenties. Banen die het beste passen bij de uitkomst van deze test zullen worden getoond.',
        },
        functions: {
            title: 'Functies',
            description:
                'Op basis van de door jouw als interessant beoordeelde functies worden bijpassende banen getoond.',
        },
        interaction_clicks: {
            title: 'Klikken',
            description:
                'Op basis van waar jij klikt op de pagina worden banen getoond die bij jou passen.',
        },
        interaction_pageviews: {
            title: "Bekeken pagina's",
            description:
                "Op basis van de pagina's die je hebt bekeken worden banen getoond die bij jou passen.",
        },
        interaction_scroll: {
            title: 'Scroll activiteit',
            description:
                'Op basis van jouw scrollactiviteit worden banen getoond die bij jou passen.',
        },
        interaction_search_actions: {
            title: 'Zoekopdrachten',
            description: 'Op bais van jouw zoekopdrachten worden banen getoond die bij jou passen.',
        },
        interaction_time: {
            title: 'Tijd',
            description:
                'Op basis van de tijd die je doorbrengt op een pagina worden banen getoond die bij jou passen.',
        },
        organizations: {
            title: 'Organisaties',
            description:
                'Op basis van de organisaties waarin jij geïnteresseerd bent worden soortgelijke banen getoond.',
        },
        study_level: {
            title: 'Opleidingsniveau',
            description:
                'Op basis van jouw opleidingsniveau worden banen die dit niveau vereisen getoond.',
        },
        working_areas: {
            title: 'Werkgebieden',
            description:
                'Rekening houdend met werkgebieden die jouw voorkeur hebben worden banen binnen dit gebied getoond.',
        },
        working_times: {
            title: 'Werktijden',
            description:
                'Rekening houdend met werktijden die jouw voorkeur hebben worden bijpassende banen getoond.',
        },
        parent_account: {
            title: 'Account',
            description:
                'Op basis van jouw accountgegevens worden banen getoond die beter bij jou passen.',
        },
        parent_ip: {
            title: 'IP Adres',
            description:
                'Op basis van jouw IP-adres worden banen getoond die beter bij jou passen.',
        },
        experience_area: {
            description:
                'Rekening houdend met werkgebieden die jouw voorkeur hebben worden banen binnen dit gebied getoond.',
        },
        zorgselect_ip: {
            title: 'IP Adres',
            description:
                'Op basis van jouw IP-adres worden banen getoond die beter bij jou passen.',
        },
        zorgselect_account: {
            description:
                'Op basis van jouw accountgegevens worden banen getoond die beter bij jou passen.',
            title: 'Account',
        },
    },
    onboarding: {
        button_back: 'Terug',
        title1: 'Bedankt voor je deelname aan e-portfolio!',
        title2: 'Start je e-portfolio ervaring door ons wat informatie over jezelf te geven!',
        title3: 'Nu we wat informatie van je hebben kunnen we het suggestieproces starten:',
        title4: 'We hebben een suggestie voor je gevonden!',
        title5: 'We hebben een vacature voor je gevonden!',
        titleStep1: 'Vul hier je gegevens in!',
        textStep1:
            'Om het meeste uit e-portfolio te halen, moeten we eerst wat informatie over jou verzamelen. Je kunt deze informatie later in je profiel wijzigen en uitbreiden.',
        titleStep2: 'Onderwijsniveau',
        textStep2:
            'Om relevante vacatures voor je te kunnen vinden, hebben we wat informatie nodig. <br /> <br /> Ten eerste: wat is je hoogste opleidingsniveau?',
        titleStep3: 'Stad',
        textStep3: 'Hoe heet de stad waarin je woont?',
        titleStep4: 'Functie',
        textStep4: 'Naar welke baan ben je op zoek?',
        titleStep5: 'Suggesties',
        textStep5: 'In het volgende scherm krijg je enkele vragen te zien:',
        titleStep7: 'We hebben een vacature voor je gevonden',
        textStep7:
            'Op basis van jouw informatie hebben wij een vacature gevonden. Laten we eens kijken!',
        jobTitlePlaceholder: 'Voer hier je gewenste functie in',
        or: 'of',
        indicateYes: 'om Ja aan te geven',
        indicateNo: 'om Nee aan te geven',
        vacancySuits: 'Past deze vacature bij jou?',
        vacancySuitsText:
            "Als je deze vacature leuk vindt, sla hem dan op zodat je kunt solliciteren. De vacature wordt opgeslagen in 'Vacatures' in het hoofdmenu.",
        vacancyDoesNotSuit: 'Niet echt iets voor jou?',
        vacancyDoesNotSuitText:
            'Maak je profiel af zodat we je beter kunnen leren kennen. Door gebruik te maken van e-portfolio zal het systeem steeds beter worden in het vinden van de juiste vacatures voor jou.',
        study_level: 'Studieniveau',
        cityPlaceholder: 'Typ hier je woonplaats',
        noCitiesFound: 'Geen woonplaatsen gevonden',
        skip: 'Overslaan en sluiten',
        click: 'Klik',
        intro: {
            step1: {
                title: 'Suggesties',
                text: 'Door een reeks vragen te beantwoorden op basis van je interesses krijg je snel suggesties voor een passende baan.',
            },
            step2: {
                title: 'Sollicitaties',
                text: 'Hier vind je een overzicht van alle openstaande en eerder door jouw verzonden sollicitaties. ',
            },
            step3: {
                title: 'Vacatures',
                text: 'Bekijk hier alle beschikbare vacatures op een locatie van jouw voorkeur.',
            },
            step4: {
                title: 'Mijn profiel',
                text: 'Dit is jouw persoonlijke pagina! Vul hier je persoonlijke gegevens in, zoals werkervaring, opleiding en je interesses, om passende vacaturesuggesties te krijgen.',
            },
            step5: {
                title: 'Werkervaring',
                text: "Voeg hier de door jouw opgedane werkervaring toe door op de knop 'Voeg nieuwe toe' te klikken. Door vervolgens de duur, je functie en het werkgebied te specificeren worden er beter passende vacatures gesuggereerd.",
            },
            step6: {
                title: 'Documenten',
                text: "Heb je een cv, een sollicitatiebrief, diploma's of andere documenten die je met je sollicitaties wilt versturen? Upload deze documenten hier.",
            },
            step7: {
                title: 'Opleidingen en cursussen',
                text: 'Het toevoegen van opleidingen en cursussen die je hebt gevolgd is een goede manier om passende vacaturesuggesties te krijgen.',
            },
            step8: {
                title: 'Jouw mogelijke beroepen',
                text: 'Om ervoor te zorgen dat de geleverde vacturesuggesties nauw aansluiten bij wat je zou willen doen, vul je hier jouw mogelijke beroepen in.',
            },
            step9: {
                title: 'Vaardigheden',
                text: 'Wat onderscheidt jou van andere kandidaten? Vul hier je vaardigheden in, zoals bijvoorbeeld waar je goed in bent en de talen die je kent!',
            },
            step10: {
                title: 'Referenties',
                text: 'Heb je referenties van vorige banen? Vul ze hier in en maak ze beschikbaar bij het solliciteren zodat potentiële werkgevers ze kunnen zien.',
            },
            step11: {
                title: 'Privacyinformatie',
                text: 'Privacy is belangrijk voor ons. Hier kun je selecteren welke van je persoonlijke gegevens je privé wilt houden of wilt delen.',
            },
            step12: {
                title: 'CV-sjablonen',
                text: 'Met CV-sjablonen kun je je eigen gepersonaliseerde CV maken. Hiermee kun je kiezen welke informatie je wilt delen als je solliciteert op een vacature.',
            },
        },
        alt1: {
            step1: {
                title: 'Vul hier je gegevens in!',
                text: 'Om een geschikte klus/les voor jou te kunnen vinden, moeten we eerst wat informatie over jou verzamelen. Je kunt deze informatie in je profiel wijzigen en toevoegen.',
            },
            step2: {
                title: 'Diploma',
                text: 'Om een geschikte klus/les voor jou te kunnen vinden, hebben we wat informatie nodig. Ten eerste welk diploma heb je behaald?',
            },
            step3: { title: 'Functie', text: 'Voor wat voor functie kan je ingezet worden?' },
            step4: { title: 'Stad', text: 'Hoe heet de stad waarin je woont?' },
            step5: {
                title: 'Werkgevers',
                text: 'Geef hier de werkgever(s) aan bij wie je een extra klus/les wilt werken. ',
                current_employer: 'Kies hier de organisatie voor wie je werkt',
                desired_employer: 'Bij welke werkgever(s) wil je graag opdrachten doen?',
                select_employer: 'Kies werkgever(s)',
                select_current_employer: 'Kies huidige werkgever',
                select_current_employer_placeholder: 'Selecteer jouw werkgever',
            },
            step6: {
                title: 'Persoonlijke Informatie',
                text: 'Om jou te kunnen koppelen aan beschikbare opdrachten hebben we een aantal gegevens van je nodig.',
                iban_explanation:
                    'Dit hebben we nodig om de opdrachten die je hebt gedaan uit te kunnen betalen.',
                phone_explanation:
                    'We hebben jouw telefoonnummer nodig om je een sms-bericht te sturen als er een klus/les beschikbaar komt.',
                flexapp_work_as_freelancer: "Ik wil (ook) ingezet worden als ZZP'er.",
            },
            step7: {
                title: 'Bedankt voor de gegevens!',
                text: 'Voordat je toegelaten wordt tot de Flex-app wordt je aanvraag behandeld door de organisatie. Wanneer je wordt toegelaten ontvang je een mail ter bevestiging. Zodra er dan een klus/les beschikbaar komt die matcht met jouw profiel, ontvang je een mail en kun je je meteen inschrijven.',
            },
        },
        planner: {
            step1: {
                title: 'Laten we jouw profiel aanvullen om opdrachten te kunnen plannen!',
                text: 'Om opdrachten te mogen plannen in de Flexapp hebben we een aantal gegevens van je nodig. Deze informatie kan je achteraf ook veranderen via jouw profiel.',
            },
            step2: { title: 'Stad', text: 'In welke stad woon je?' },
            step4: {
                title: 'Bedankt',
                text: 'Zodra jouw werkgever de aanvraag heeft goedgekeurd, ontvang je een mail.',
            },
            step3: {
                title: 'Organisatienaam',
                text: 'Door in te vullen voor welke organisatie je werkt, kan de betreffende werkgever jouw account goedkeuren.',
            },
        },
        textStep9: 'Dit is het einde van de onboarding. Je kunt nu e-portfolio gebruiken!',
        titleStep9: 'Bedankt voor de gegevens!',
        study_level_placeholder: 'Kies je studieniveau',
        jobTitle: 'Werkfunctie',
        titleStepVindwerk: 'Competenties & voorkeuren',
        textStepVindwerk: '',
        stepVindwerk: {
            competence: 'Kies jouw competenties',
            competences: 'Competenties',
            open_for_projects: 'Open voor tijdelijke opdrachten',
        },
        vindwerkstep: '',
        typeYourCity: 'Typ je stad en selecteer deze in de lijst',
        textStepOutroFlex:
            'Je bent klaar met je registratie voor VOTA Flex! Een planner zal nu je aanvraag bekijken. Je krijgt een mail wanneer je bent goedgekeurd om extra opdrachten te werken.',
        flexterm: 'Ga naar Flexapp',
        textStepOutroPlanner:
            'Bedankt voor je aanmelding als planner. Je krijgt een mail zodra je organisatie je goedkeurt.',
        plannerDone: 'Ok',
        creation_options: { title: 'Jouw E-Portfolio' },
        textStepOutroLateral:
            'Bedankt voor het maken van je E-portfolio. Wil je een persoonlijk gesprek over je mogelijkheden om over te stappen naar het onderwijs? Maak een afspraak met een van onze intakecoördinatoren. Stuur een e-mail naar <a href="mailto:intakecoordinator@vota.nl">intakecoordinator@vota.nl</a>.',
        step: { discovery: { title: 'Hoe ken je ons?' } },
        documents: {
            title: 'Jouw documenten',
            description:
                'Upload hier alvast je CV, dat helpt met het compleet maken van jouw E-Portfolio',
        },
    },
    diplomas: {
        hint_search_diploma_placeholder: 'Zoek naar de naam van je opleiding...',
        no_diplomas_found: 'Geen schoolopleiding gevonden',
        select_one: 'Kies een schoolopleiding',
        teacher: 'Leraar',
        mentor: 'Begeleider',
        coach: 'Coach',
        recipient: 'Zorgvrager',
        other_student: 'Medestudent',
        my_evaluation: 'Mijn oordeel',
        back_to_all: 'Terug naar alle schoolopleidingen',
        starter: 'Starter',
        advanced: 'Gevorderd',
        professional: 'Beroepsbekwaam',
        pending: 'in afwachting',
        remove: 'Verwijderen',
        evaluate_yourself: 'Beoordeel jezelf',
        invite_a: 'Stuur een uitnodiging naar je',
        invite_evaluator: 'Nodig een beoordelaar uit',
        title: 'Schoolopleiding',
        evaluations: 'Beoordelingen',
        not_invited: 'Niet uitgenodigd',
        ask_feedback: 'Feedback aanvragen',
        ask_feedback_from_a: 'Vraag om feedback van je',
        ask_for_feedback: 'Vraag om feedback',
        remarks: 'Eventuele opmerkingen',
        pending_evaluation: 'Wacht op beoordeling',
        evaluate: 'Beoordeel kwalificatie',
        remove_confirm: 'Weet je zeker dat je deze schoolopleiding wilt verwijderen?',
        new_evaluator: 'Nieuwe beoordelaar',
        show_graph: 'Laat grafiek zien',
        legend_help: 'Klik op de legenda om een grafiek te verbergen/weer te geven.',
        graph: 'Grafiek',
        self_evaluation_first: 'Beoordeel eerst jezelf, voordat je anderen uitnodigt',
        hint_search_school_placeholder: 'Zoek naar je school...',
        hint_search_placeholder: 'Zoek naar je opleding...',
        crebo_no: 'Crebo nr',
        school_specific: 'Diploma alleen voor deze school',
        no_schools_found: 'Geen scholen gevonden',
    },
    password: {
        requirements: 'Het wachtwoord moet:',
        minimum_characters: 'minstens 8 karakters bevatten',
        lowercase_letter: 'een kleine letter bevatten',
        uppercase_letter: 'een hoofdletter bevatten',
        number: 'een cijfer bevatten',
        special_character: 'een speciaal teken bevatten (*,!,?,@,#)',
        match: 'gelijk zijn',
        not_old_password: 'niet het oude wachtwoord zijn',
    },
    pages: {
        education: 'Opleidingen',
        working_experience: 'Werkervaring',
        skills: 'Vaardigheden',
        achievements: 'Prestaties',
        documents: 'Documenten & CV',
        flexible_shifts: 'Flexibele opdrachten',
        events: 'Evenementen',
        extra_training: 'Bijscholing',
        open_badges: 'OpenBadges',
        reintegration: 'Reïntegratie traject',
    },
    evaluation_descriptions: {
        starter:
            'De (aankomend) professional heeft kennis genomen van het werkproces, kan dit onder begeleiding toepassen en daarbij reflecteren op eigen handelen.',
        advanced:
            'De (aankomend) professional kan tijdens deze fase aantonen dat zij de kennis van het werkproces onder begeleiding / gedeeltelijk zelfstandig kan toepassen.\nOok kan hij / zij reflecteren op het eigen functioneren en vraagt om feedback van collega`s.',
        professional:
            'De (aankomend) professional kan tijdens deze fase aantonen dat zij de opgedane kennis van het werkproces geheel zelfstandig in de praktijk heeft toegepast.\nZelfreflectie is vanzelfsprekend en zij laat een professionele houding zien.',
    },
    eelloo: {
        test: 'Test',
        request_date: 'Aanvraagdatum',
        completed: 'Voltooid?',
        share_with_advisor: 'Delen met adviseur',
        no_tests: 'Er zijn geen eelloo tests voor jou beschikbaar gemaakt.',
    },
    ecosystem: {
        page_title: 'Kom meer te weten over het e-portfolio ecosysteem',
        page_intro:
            'Verschillende systemen zijn met elkaar verbonden om zoveel mogelijk mensen binnen de gezondheidssector met elkaar in contact te brengen.<br /> <br />\nMaar hoe zijn ze gerelateerd?',
        in_partnership_with: 'In samenwerking met',
        schools: {
            title: 'Scholen & Opleidingen',
            explanation:
                'Via RAAT en e-portfolio kunnen scholen en trainers de aansluiting tussen onderwijs en banen verbeteren, waardoor het onderwijs zich kan richten op specifieke gezondheidssectoren in Nederland.',
            url: 'https://raat.wgvzorgenwelzijn.nl/',
        },
        view_website: 'Bekijk website',
        students: {
            explanation:
                'Via Kwalificatiedossiers en e-portfolio kunnen studenten hun voortgang op school volgen en kunnen leraren helpen bij het registreren van de scores van studenten, en ook feedback en aanbevelingen geven.',
            title: 'Studenten & Docenten',
            subtitle: 'Kwalificatiedossiers',
            url: 'https://mijnportfolio.eu/homepage',
        },
        advisors: {
            explanation:
                'Via Clientvolgsysteem en e-portfolio kunnen adviseurs en re-integranten de voortgang van re-integratie op de werkplek volgen en mensen zo snel mogelijk weer aan het werk helpen.',
            title: '',
            url: 'https://cvs.zorgselect.nl/',
        },
        candidates: {
            explanation:
                'Via ZorgSelect en e-portfolio kunnen kandidaten eenvoudig zoeken naar  en solliciteren op vacatures met betrekking tot gezondheidszorg. Het vinden van banen in de gezondheidszorg is nog nooit zo eenvoudig geweest.',
            title: 'Kandidaten',
            url: 'https://www.zorgselect.nl/',
        },
        employers: {
            explanation:
                'Via Zorg en Welzijn en e-portfolio kunnen werkgevers eenvoudig vacatures publiceren en wervingstools toevoegen voor kandidaten.',
            title: 'Werkgevers',
            url: 'https://www.zorgselect.nl/',
        },
        flexworkers: {
            explanation:
                'Via de Flex-app en e-portfolio kunnen tijdelijk lege onderwijsgerelateerde opdrachten worden ingevuld door kandidaten die bereid zijn om extra opdrachten te werken.',
            title: 'Flexwerkers',
        },
        interested: {
            explanation:
                'Via Ontdek de Zorg en e-portfolio kunnen mensen die geïnteresseerd zijn in gezondheid meer leren over de gezondheidssector en het personeelsbestand in gezondheidssectoren in Nederland promoten.',
            title: 'Personen die interesse hebben in de zorg',
            url: 'https://wgvzorgenwelzijn.nl/contactpunten',
        },
        pie_instruction: 'Klik op de schijven om meer te weten te komen over elk systeem!',
    },
    notification: {
        onboarding_not_complete:
            'Om een geschikte klus/les voor jou te kunnen vinden, hebben we wat extra informatie nodig die we niet hebben gevonden in je e-portfolio. ',
        show_onboarding: 'Informatie toevoegen',
        button_yes: 'Ja',
        button_no: 'Nee',
        suggest_flexapp: 'Wil je deelnemen aan de flexapp?',
        wait_for_activation:
            'Je reactie is opgeslagen. U moet wachten tot uw werkgever uw account activeert om Flexapp te gebruiken.',
        pending_acceptance:
            'Een van de organisaties waarvoor je hebt gekozen om voor te werken, moet je goedkeuren om toegang te krijgen tot Flex-app.',
    },
    general: { loading: 'Bezig met laden...' },
    saxionModules: {
        location: 'Locatie',
        profession: 'Vakgebied',
        starts_after: 'Begint vanaf',
        starts_before: 'Begint voor',
        search_courses: 'Doorzoek modules',
        learn_more: 'Details',
        supportText:
            'Wil je tijdens het werken extra trainingen volgen om je vaardigheden te verbeteren en misschien ook om andere functies te kunnen uitvoeren? Hieronder zie je een overzicht van mogelijke modules die je kunt volgen naast je eventuele baan om meer vaardigheden te ontwikkelen. Deze modules worden door externe opleidingsinstituten aangeboden op bepaalde momenten van het jaar.',
    },
    about: {
        title: 'Over E-Portfolio',
        intro: 'De E-Portfolio APP stelt jou als e-portfoliohouder in staat je levenslange leerproces, opgedaan door studies, cursussen, stages, hobby’s, arbeid, competenties en ervaringen, te bundelen, bij te houden en beschikbaar te stellen.',
        main_text:
            'De samensteller (de e-portfoliohouder) is eigenaar van zijn portfolio en houdt alle rechten en zeggenschap over de inhoud daarvan. Jouw ingevoerde data wordt opgeslagen en op een intelligente manier ingezet voor het jou aanbieden van betere vacaturematches, het doen van gerichte adviezen op het gebied van employability scans, diverse testen en opleidingen, elearning, EVC quickscans, etc. Het e-portfolio is bruikbaar in meerdere domeinen en in meerdere sectoren. Het is bruikbaar in alle situaties waarin jij kan leren, ( formeel en informeel) in het onderwijs, in de arbeidsbemiddeling en bij een werkgever. Het e-portfolio kan gegevens bevatten die jij zelf hebt gecreëerd, samengesteld dan wel geüpload, maar ook direct gekoppelde (gecertificeerde) kopieën van gegevens of documenten verkregen uit deelname van cursussen/opleidingen, testen en elearning. Gecombineerd met VOTA ontstaat zodoende een compleet instrument voor loopbaanontwikkeling, arbeidsmarktoriëntatie, (sectorale en intersectorale) en mobiliteit.',
    },
    common: { none: 'Geen' },
    profilePictureModal: {
        title: 'Bewerk afbeelding',
        zoom: 'Zoom',
        rotation: 'Rotatie',
        save: 'Opslaan',
        cancel: 'Annuleren',
    },
    creation_options: {
        sub_title: 'Waarom maak jij een E-portfolio account?',
        option_already_teaching_vota: 'Ik geef al les aan een van de VOTA scholen*.',
        option_already_teaching_not_vota: 'Ik ben docent, alleen nog niet aan een VOTA school*.',
        option_studying: 'Ik ben momenteel bezig met een studie om mijn bevoegdheid te halen.',
        option_exploring: 'Ik ben aan het onderzoeken of lesgeven iets is voor mij.',
        onboarding_title: 'Jouw E-Portfolio aanmaken',
        validation_error: 'Selecteer a.u.b. een van de onderstaande opties',
        school_list: '* Vota-scholen zijn de volgende:',
    },
    server_error:
        'Er is iets fout gegaan, probeer het later nog een keer, wij werken aan een oplossing.',
    no_date_known: 'Geen datum bekend',
}
